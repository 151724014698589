// 对接口进行封装处理
import request from './config'

/**
 * 获取公告新闻
 */
export function getNewsList (data) {
  return request({
    url: '/news/list?corpid=whno8q8t30huiom1',
    method: 'post',
    data
  })
}
/**
 * 获取新闻详情
 */
export function getNewsDetail (data) {
  return request({
    url: '/news/read?corpid=whno8q8t30huiom1',
    method: 'post',
    data
  })
}
/**
 * 获取省市接口
 */
export function getRegion(data) {
  return request({
    url: 'xwzc/user/product/areatree?corpid=whno8q8t30huiom1',
    method: 'post',
    data
  })
}
/**
 * 获取产品类型
 */
export function getProductType(data) {
  return request({
    url: '/xwzc/user/productclass/getall?corpid=whno8q8t30huiom1',
    method: 'post',
    data
  })
}
/**
 * 获取产品列表
 */
export function getProductList(data) {
  return request({
    url: '/xwzc/user/product/list?corpid=whno8q8t30huiom1',
    method: 'post',
    data
  })
}
/**
 * 获取申请二维码
 */
export function getUrl(data) {
  return request({
    url: '/xwzc/user/order/applyurl',
    method: 'post',
    data
  })
}
/**
 * 获取申请企业列表（模糊查询）
 */
export function getCompany(data) {
  return request({
    url: `/xwzc/user/open/simplelist?corpid=whno8q8t30huiom1&keyword=${data.keyword}`,
    method: 'post',
    data
  })
}
/**
 * 获取短信验证码
 */
export function getVerify(data) {
  return request({
    url: '/xwzc/user/open/sendcaptcha?corpid=whno8q8t30huiom1',
    method: 'post',
    data
  })
}
/**
 * 申请额度
 */
export function sendQuota(data) {
  return request({
    url: '/xwzc/user/order/create?corpid=whno8q8t30huiom1',
    method: 'post',
    data
  })
}
