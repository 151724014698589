<template>
  <div class="dialog-page">
    <el-dialog
      title="隐私协议"
      :visible.sync="showDialogA1"
      width="60%"
      :show-close="false"
      :center="true"
    >
      <div class="content">
        <p>引言</p>
        <p>
          北京小微之春科技有限公司（以下简称“我们”）高度重视用户（以下简称“您”）的隐私。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》（以下简称“本政策”）向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本政策与您所使用的
          北京小微之春科技有限公司产品和服务息息相关，希望您仔细阅读，在需要时，按照本政策的指引，作出您认为适当的选择。本政策中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您的理解。
        </p>
        <p style="font-weight: bold">
          您使用或继续使用我们的服务，即意味着同意我们按照本政策收集、使用、储存和分享您的相关信息。
        </p>
        <p>本隐私政策将帮助您了解以下内容：</p>
        <p>一、我们如何收集和使用您的个人信息</p>
        <p>二、我们如何共享、转让、公开披露您的个人信息</p>
        <p>三、我们如何存储及保存您的个人信息</p>
        <p>四、未成年人的个人信息保护</p>
        <p>五、本政策适用的范围</p>
        <p>六、您的个人信息权利</p>
        <p>七、本政策的更新和通知</p>
        <p>八、您如何联系我们</p>
        <p>相关术语及定义：</p>
        <p>1、用户或您：指 北京小微之春科技有限公司平台的用户。</p>
        <p>
          2、本平台：指 北京小微之春科技有限公司及其关联方开发、运营的
          北京小微之春科技有限公司平台网页端、微信公众号。
        </p>
        <p>
          3、关联公司：指相互之间存在关联关系，关联关系是指
          北京小微之春科技有限公司控股股东、实际控制人、董事、监事、高级管理人员与其直接或者间接控制的企业之间的关系，以及可能导致利益转移的其他关系。
        </p>
        <p>
          4、本产品和/或服务：指
          北京小微之春科技有限公司及其关联公司通过本平台向您提供的产品或服务。
        </p>
        <p>
          5、个人信息：是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
        </p>
        <p>
          6、敏感个人信息：是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。
        </p>
        <p>
          7、个人信息的处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。
        </p>
        <p>一、我们如何收集和使用您的个人信息</p>
        <p>
          我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
        </p>
        <p>
          1、 用户注册和登录。为了为您开立
          北京小微之春科技有限公司平台账户，我们需要收集您的真实企业名称、申请人姓名、手机号码。为了确保我们是在为您本人提供服务，我们还需要收集发送至您手机的短信验证码。如果您拒绝提供上述信息，您将无法注册
          北京小微之春科技有限公司平台账户，但不影响您使用浏览功能。
        </p>
        <p>
          当您成功注册成为我们的用户后，您可以使用手机号、短信验证码登录
          北京小微之春科技有限公司平台。
        </p>
        <p>
          2、
          企业信息管理。为了便于维护您的企业信息，我们需要收集您所在企业的名称、企业注册地、企业法定代表人姓名、企业注册时间、法人变更时间等信息。如您并非法定代表人本人，请您确保在填写上述信息前已获得法人代表人的合法授权。如果您拒绝提供上述信息，可能导致部分功能无法使用。
        </p>
        <p>
          3、
          贷款意愿和身份核实。为了确认您的贷款意愿、核实贷款申请人的身份，我们需要收集您填写的预期贷款金额、贷款人身份信息（企业法定代表人或股东），如果贷款人为股东的，我们还需要收集股东姓名、所占股份、股东身份证号、手机号信息，如您并非股东本人，请您确保在填写上述信息前已获得股东的合法授权。如您拒绝提供上述信息，我们将无法确认您的贷款意愿和实际身份。
        </p>
        <p>
          4、
          数据获取授权。在您授权我们获取企业相关数据时，为了核实您确为企业法定代表人本人或已取得法定代表人的授权，我们会向法定代表人的手机发送验证短信码，您需要输入手机验证码以授权我们获取企业相关数据。如您拒绝提供上述信息，我们将无法获取企业相关数据。
        </p>
        <p>
          5、
          数据上传。为了更好地了解企业相关情况，我们需要收集企业发票数据、企业税务数据并作为贷款授信的依据。请您在主动上传上述企业数据前已取得企业的合法授权。如您拒绝提供上述信息，我们将无法获取企业相关数据，可能无法确认企业贷款授信。
        </p>
        <p>二、我们如何共享、转让、公开披露您的个人信息</p>
        <p>
          我们充分知晓因违法共享、公开披露个人信息对个人信息主体造成损害时所应承担的法律责任，我们对您的个人信息承担保密义务，不会出售或出租您的任何信息，对于您的个人信息的一切委托处理、共享、公开披露，我们将严格按照以下条款进行：
        </p>
        <p>1、委托处理</p>
        <p>
          为了提高效率、降低成本，我们可能会委托第三方专业服务提供商代表我们在本政策所述的目的范围内处理您的个人信息。对我们委托处理个人信息的公司、组织和个人，我们会通过书面协议、现场审计等方式要求其遵守严格的保密义务并采取有效的保密措施，要求其只能在约定的委托范围内处理您的个人信息，而不得出于其自身利益使用您的个人信息。
        </p>
        <p>2、共享</p>
        <p>我们不会与其他公司、组织和个人共享您个人信息，但以下情况除外：</p>
        <p>
          （1）
          取得您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
        </p>
        <p>（2） 为订立、履行您作为一方当事人的合同所必需的情况下的共享。</p>
        <p>
          （3）
          为帮助您完成贷款的申请，我们需要将您的个人信息提供给有关金融机构，具体以您拟申请的贷款产品为准。在共享个人信息前，我们将以独立的授权书在您申请贷款的页面征求您的同意。
        </p>
        <p>
          （4）
          履行法定义务所必需的共享：我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。
        </p>
        <p>3、公开披露</p>
        <p>我们仅会在以下情形下，公开披露您的个人信息：</p>
        <p>（1） 获得您明确同意后；</p>
        <p>
          （2）
          基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须具有与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；
        </p>
        <p>
          （3）
          基于我们正常管理与维护本平台秩序的需要，对违规账号、欺诈等严重损害我们合法权益的行为进行处罚公告，公告信息主要包括用户账号、用户违规行为，不会包括您的个人信息、敏感个人信息。
        </p>
        <p>4、转让</p>
        <p>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p>（1） 在获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>
          （2）
          根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
        </p>
        <p>
          （3）
          在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您的个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>5、共享、公开披露个人信息授权同意的例外情形</p>
        <p>
          根据法律法规的规定，在下述情况下，共享、转让、公开披露个人信息无需事先征得您的授权同意：
        </p>
        <p>（1） 与我们履行法律法规规定的义务相关的；</p>
        <p>（2） 与国家安全、国防安全直接相关的；</p>
        <p>（3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>（4） 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>
          （5）
          出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的。
        </p>
        <p>（6） 您自行向社会公众公开的个人信息；</p>
        <p>
          （7）
          从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        </p>
        <p>
          根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>
        <p>三、我们如何存储及保护您的个人信息</p>
        <p>
          1、我们会采取加密等技术措施存储您个人信息，确保信息安全。我们仅在本政策所述目的所必须期间和法律法规及监管规定的时间限内存储您的个人信息。
        </p>
        <p>
          2、我们依照法律法规的规定，将您的个人信息存储于中华人民共和国境内。除法律或相关法规另有约定外，我们在为提供服务之目的所必需的期间内保留您的个人信息，但您要求我们立即删除或注销账户、或法律法规另有规定的除外。
          如果我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。
        </p>
        <p>
          3、我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用数据传输加密（例如SSL）、数据存储加密来保护您提供的个人信息。我们还参考金融行业网络拓扑IDC架构，实施系统安全保护及定期渗透测试，平台严格遵循SDLC开发安全流程。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
        </p>
        <p>四、未成年人的个人信息保护</p>
        <p>
          请您特别注意，我们的产品和/或服务仅面向企业用户及成年人用户提供，若您为未成年人，请立即停止使用我们的产品或服务。
        </p>
        <p>
          如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
        </p>
        <p>五、本政策的适用范围</p>
        <p>
          本隐私政策仅适用于
          北京小微之春科技有限公司平台提供的产品或服务，不适用于通过
          北京小微之春科技有限公司平台接入的第三方产品和/或服务以外第三方平台提供的产品或服务，您在选择使用第三方产品和/或服务前，应充分了解第三方的产品/或服务的功能及其政策（个人信息保护政策），以便审慎作出决策。我们对任何第三方使用由您提供的信息不承担任何责任。
        </p>
        <p>六、您的个人信息权利</p>
        <p>
          我们非常重视您的个人信息权利，我们将尽一切可能采取适当的技术手段，保障您对于您个人信息的查阅、复制、更正、补充、删除、撤回同意授权、注销帐号、投诉举报等权利。在您行使上述权利前，我们可能会要求您进行身份验证，以保障账户安全。
        </p>
        <p>七、本政策的更新和通知</p>
        <p>
          1、我们可能适时修订本政策的条款，该等修订构成本政策的一部分。对于重大修订，如该等修订造成您在本政策下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送短信或以其他方式通知您。
        </p>
        <p>
          2、未经您明确同意，我们不会削减您及您按照本政策所应享有的权利。如果您在本政策生效后继续使用本平台产品或服务的，即表示您同意更新后本政策的全部内容，且已充分阅读、理解更新后政策的全部内容以及愿意接受更新后政策的约束。
        </p>
        <p>3、本政策所指的重大变更包括但不限于：</p>
        <p>
          （1）
          我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等
        </p>
        <p>
          （2）我们在投资人、股东结构、组织结构等方面方式重大变化，比如企业并购、业务调整、破产重整等；
        </p>
        <p>
          （3）收集、使用、存储个人信息的范围、目的、规则发生重大变化，比如用户主体变更、用户的个人信息变更；
        </p>
        <p>（4）个人信息共享、转让、公开披露的对象、范围、目的发生重大变化；</p>
        <p>
          （5）您管理的个人信息的方式，或者询问、投诉的渠道和机制以及联络方式发生变化；
        </p>
        <p>（6）现行法律、行政法规、监管规定以及行业规范发生重大变化；</p>
        <p>（7）个人信息安全影响评估报告表明存在高风险时；</p>
        <p>（8）其他可能对您的个人信息产生重大影响的变化。</p>
        <p>八、您如何联系我们</p>
        <p>
          1、如果您对本政策有任何疑问、意见、建议或者需要我们响应您的个人信息权利请求，您可以通过以下方式与我们联系：
        </p>
        <p>（1） 本平台的客服热线：400- 888-3969</p>
        <p>（2） 联系地址：【北京市海淀区信息路甲28号12层D座12B】。</p>
        <p>
          2、一般情况下，我们自收到您的反馈并在验证您的身份后，于十五日内作出回复。如果您对我们的回复不满意，您有权向
          北京小微之春科技有限公司平台运营者所在地有管辖权的法院提起诉讼。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog1">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="用户服务协议"
      :visible.sync="showDialogB1"
      width="60%"
      :show-close="false"
      :center="true"
    >
      <div class="content">
        <p>1.特别提示</p>
        <p>
          1.1 北京小微之春科技有限公司（以下简称“我们"或“
          北京小微之春科技有限公司平台”）按照本协议的规定及《隐私政策》等在使用过程中告知您的平台规则或协议提供基于互联网以及移动网的金融服务（以下简称“服务“），为获得服务，服务使用人（以下简称“用户”或“您”）应当基于了解本协议全部内容，在独立思考的基础上认可、同意本协议的全部条款并按照页面上的提示完成全部的注册程序。您在进行注册程序过程中勾选了本条例即表示您完全接受《用户服务协议》（以下简称“本协议”）及我们公示的各项规则、规范。如您代表一家公司或其他法律主体进行注册或以其他允许的方式实际使用本服务，您有义务使该公司或该法律主体遵守本协议条款的约束。
        </p>
        <p>
          1.2您注册成功后，我们将为您基于服务使用的客观需要而在申请、注册服务时，按照注册要求提供的帐号开通服务，您有权在我们为您开通、并同意向您提供服务的基础上使用服务。您使用服务过程中，须对其账号下使用服务的行为、对任何由您账号通过服务发布、公开的信息，及对由此产生的任何后果承担全部责任。
        </p>
        <p>
          1.3在注册帐号或使用本服务的过程中，我们需要收集您的信息，并按照《隐私政策》的约定对您的个人信息进行处理和保护。若国家法律法规或政策有特殊规定的，您需要提供真实的身份信息。若您提供的信息不完整，则无法使用本服务或在使用过程中受到限制。
        </p>
        <p>
          1.4为提高您的服务体验和满意度，您同意我们将基于您的操作行为对您的行为数据进行调查研究和分析，从而进一步优化服务。您同意并授权
          北京小微之春科技有限公司，可通过您所提交的联系方式与您进行联系，以确保您可在第一时间知晓前述公司为您提供的金融产品、征信产品及服务改善等相关内容；您同意并授权，如您使用
          北京小微之春科技有限公司为您提供的相关金融产品，前述公司可从您已申请服务的金融机构获取您的贷款情况，包括但不限于贷款金融、贷款期限、贷款状态等，从而在您当前使用的金融产品的前提下，进一步为您提供还款提示、贷后提醒、操作指引等优化服务。
        </p>
        <p>2.服务内容</p>
        <p>
          2.1
          北京小微之春科技有限公司服务的具体内容由我们根据实际情况提供，包括但不限于您通过其帐号使用融资规划功能、备用金申请功能、智能匹配功能、贷后管理功能等，我们有权对其提供的服务或产品形态进行升级或其他调整，并将及时告知您。
        </p>
        <p>
          2.2企业实名认证：请您注意，
          北京小微之春科技有限公司平台在向用户提供贷款申请服务时，为了识别您的真实身份并判断您是否具备贷款产品的申请条件，我们将对您所在企业进行企业实名认证，为此我们需要您填写手机号码或其它身份信息（包括但不限于企业名称等企业信息和法定代表人身份证、手机号码等法定代表人信息）。
        </p>
        <p>
          2.3受限于技术手段的有限性，在您完成企业实名认证、法定代表人手机短信验证码认证等一系列身份识别流程后，我们将视为您（且您应保证）已经获得被认证企业的合法有效、充分明确的授权，且不会违反被认证企业的任何内部规章制度及对外签署的协议。否则，若因此违反有关法律法规或企业内部章程规定的，您应当自行承担相应的责任，
          北京小微之春科技有限公司平台若因此遭受损失的，亦有权向您追究相应责任及索赔。若被认证企业认为存在被无权认证、冒名或其他违法违规情况，可在第一时间与我们的客服人员取得联系，我们将及时响应并采取相应的措施。
        </p>
        <p>
          2.4您理解，我们仅提供与服务相关的技术服务等，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由您自行负担。
        </p>
        <p>3.服务变更、中断或终止</p>
        <p>
          3.1鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他我们无法控制的情形），您同意我们有权随时中断或终止部分或全部的服务，若发生该等中断或中止服务的情形，我们将尽可能及时通过网页公告、系统通知、短信或其他合理方式通知受到影响的您。
        </p>
        <p>
          3.2您理解，我们需要定期或不定期地对提供服务的平台（如互联网网站基础设施设备等）或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，我们无需为此承担任何责任，但我们应尽可能事先进行通告。
        </p>
        <p>
          3.3如发生下列任何一种情形，我们有权随时中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任：
        </p>
        <p>3.3.1您提供的资料不真实；</p>
        <p>3.3.2您违反法律法规国家政策或本协议中规定的使用规则；</p>
        <p>3.3.3您在使用收费服务时未按规定付费。</p>
        <p>4.使用规则</p>
        <p>
          4.1您注册
          北京小微之春科技有限公司账号，制作、发布、传播信息内容的，应当使用真实身份信息，不得以虚假、冒用的居民身份信息、企业注册信息、组织机构代码信息进行注册。
        </p>
        <p>
          4.2如您违反前述4.1条之约定，依据相关法律、法规及国家政策要求，我们有权随时中止或终止您对服务的使用且不承担责任。
        </p>
        <p>
          4.3您在使用服务时，我们有权基于安全运营、社会公共安全的需要或国家政策的要求，要求您提供准确的个人资料，如您提供的个人资料有任何变动，导致您的实际情况与您提交给我们的信息不一致的，您应及时更新。
        </p>
        <p>
          4.4您不应将您的帐号转让或出借予他人使用。如您发现自身帐号或服务遭他人非法使用，应立即通知我们协调处理。因我们无法对使用您账号行为是否已经您授权而作出甄别，您可能需对该账号项下所为的行为承担相应的责任，如您与我们或其他第三方之间因此而出现纠纷的，您可通过协商或利用法律途径寻求解决方法。
        </p>
        <p>
          4.5您同意我们在提供服务过程中以各种方式（包括但不限于电话销售、邮件投递、短信等）向您自行提供的联系方式或我们另行收集的您的联系方式投放商业性广告或其他任何类型的商业信息，并且，您同意接受我们通过电话营销、电子邮件或其他方式向您发送商品促销或其他相关商业信息。
        </p>
        <p>
          4.6对于您通过服务公开发布的任何内容，您同意我们在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。
        </p>
        <p>
          4.7您在使用服务过程中，不得在网站上制作、复制、上传、发布、传播含有下列内容之一的信息：
        </p>
        <p>4.7.1违反宪法确定的基本原则的；</p>
        <p>4.7.2危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>4.7.3损害国家荣誉和利益的；</p>
        <p>4.7.4煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>4.7.5破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>4.7.6散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>4.7.7散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
        <p>4.7.8侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>4.7.9煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
        <p>4.7.10以非法民间组织名义活动的；</p>
        <p>
          4.7.11不符合法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；
        </p>
        <p>
          4.7.12发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
        </p>
        <p>4.7.13涉及他人隐私、个人信息或资料的；</p>
        <p>
          4.7.14发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的；
        </p>
        <p>4.7.15干扰我们正常运营和侵犯其他您或第三方合法权益内容的信息。</p>
        <p>
          4.7.16含有中国法律、法规以及任何具有法律效力之规范所限制或禁止的其它内容的。
        </p>
        <p>
          4.8我们针对某些特定的服务的使用通过各种方式（包括但不限于网页公告、系统通知、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，您如使用该等服务，视为您同意该等声明、通知、警示的内容。
        </p>
        <p>
          4.9您承诺自己在使用本网站服务的所有行为均遵守国家法律、法规和本网站的相关规定以及各种社会公共利益或公共道德。对于任何法律后果的发生，您将以自己的名义独立承担所有相应的法律责任，我们不承担任何责任。
        </p>
        <p>
          4.10您在使用服务时如发现服务系统或其他相关系统的信息安全漏洞需及时通知我们，禁止对我们服务系统进行漏洞扫描、渗透测试等可能造成我们损失的行为。
        </p>
        <p>
          4.11除非法律允许或
          北京小微之春科技有限公司书面许可，你使用本产品或服务过程中不得从事下列行为：
        </p>
        <p>4.11.1删除本产品及其副本上关于著作权的信息；</p>
        <p>
          4.11.2对本产品进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本产品的源代码；
        </p>
        <p>
          4.11.3对
          北京小微之春科技有限公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
        </p>
        <p>
          4.11.4对本产品或者本产品运行过程中释放到任何终端内存中的数据、产品运行过程中客户端与服务器端的交互数据，以及本产品运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非
          北京小微之春科技有限公司经授权的第三方工具/服务接入本产品和相关系统；
        </p>
        <p>
          4.11.5通过修改或伪造产品运行中的指令、数据，增加、删减、变动产品的功能或运行效果，或者将用于上述用途的产品、方法进行运营或向公众传播，无论这些行为是否为商业目的；
        </p>
        <p>
          4.11.6自行或者授权他人、第三方产品对本产品及其组件、模块、数据进行干扰；
        </p>
        <p>4.11.7其他未经 北京小微之春科技有限公司明示授权的行为。</p>
        <p>
          4.12除非法律允许或
          北京小微之春科技有限公司书面许可，使用本产品或服务过程中不得从事下列行为：
        </p>
        <p>4.12.1提交、发布虚假信息，或冒充、利用他人名义的；</p>
        <p>4.12.2诱导其他您点击链接页面或分享信息的；</p>
        <p>4.12.3虚构事实、隐瞒真相以误导、欺骗他人的；</p>
        <p>4.12.4侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
        <p>
          4.12.5其他违反法律法规规定、侵犯其他您合法权益、干扰产品正常运营或
          北京小微之春科技有限公司未明示授权的行为。
        </p>
        <p>5.知识产权</p>
        <p>
          5.1我们提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受著作权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。我们不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向您或任何第三方负责。
        </p>
        <p>
          5.2服务中涉及的“
          北京小微之春科技有限公司”等文字或图形及其组成，均是我们的商标。未经我们事先书面同意，您不得有擅自使用或展示这些商标，一经发现，我们将追究法律责任。
        </p>
        <p>
          5.3我们为提供服务而使用的，或者第三方针对服务开发的在平台上运行、供您使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，您不得对该软件进行反向工程（reverse
          engineer）、反向编译（decompile）或反汇编（disassemble）。
        </p>
        <p>6.免责声明</p>
        <p>
          6.1您在使用服务的过程中应遵守国家法律法规及政策规定，因其使用服务而产生的行为后果由您自行承担。
        </p>
        <p>
          6.2通过服务发布的任何信息，及通过服务传递的任何观点不代表我们之立场，我们亦不对其完整性、真实性、准确性或可靠性负责。您对于可能会接触到的非法的、非道德的、错误的或存在其他失宜之处的信息，及被错误归类或是带有欺骗性的发布内容，应自行做出判断。在任何情况下，对于任何信息，包括但不仅限于其发生的任何错误或遗漏；或是由于使用通过服务发布、私信、传达、其他方式所释出的或在别处传播的信息，而造成的任何损失或伤害，应由相关行为主体承担全部责任。
        </p>
        <p>
          6.3鉴于外部链接指向的网页内容非我们实际控制的，因此我们无法保证为向您提供便利而设置的外部链接的准确性和完整性。
        </p>
        <p>
          6.4您完全理解并同意，本服务涉及到互联网等相关服务，可能会受到不可抗力（包括但不限于战争、台风、水灾、火灾、地震、罢工、暴动等）、计算机病毒或黑客攻击、互联网络等造成的服务中断或不能满足您要求的风险，使用本服务的您须承担以上风险，我们不承担任何责任。
        </p>
        <p>
          6.5对网站系统发生故障影响到本服务的正常运行，我们承诺将及时处理、修复。但您因此而产生的经济和精神损失，我们不承担任何责任。此外，我们保留不经事先通知进行维护、升级或其他目的暂停本服务的权利，由此产生的损失我们不承担任何责任。
        </p>
        <p>
          6.6您在使用过程中遭受的来自任何他人的威胁、诽谤或其他非法行为，您对此必须自己承担责任，我们对本服务不做任何类型的保证；对因此导致任何您产生直接或间接地损失，我们不承担任何责任。
        </p>
        <p>
          6.7您在注册账号后，如果出现违反法律或本协议的情形，有权回收该账号，由此给您带来的任何问题均由您自行承担。
        </p>
        <p>
          6.8如发生下列任何一种情形，
          北京小微之春科技有限公司有权随时中断或终止向您提供服务而无需通知该您：
        </p>
        <p>6.8.1提供的资料不真实或存在侵犯第三方知识产权或其他权利的情形；</p>
        <p>6.8.2违反本服务条款的规定；</p>
        <p>6.8.3按照政府部门和/或司法机关的要求；</p>
        <p>
          6.8.4恶意抢注企业名称，我们收到第三方投诉且该第三方出具相应证据的；
        </p>
        <p>6.8.5其他我们认为是符合整体服务需求的特殊情形。</p>
        <p>
          6.9您知悉并同意，我们可能会与第三方合作向您提供产品和/或服务，并由第三方向您提供该产品和/或服务的升级、维护、客服等后续工作，由该等第三方对该产品和/或服务的质量问题个人信息处理或其本身的原因导致的一切纠纷或您损失承担责任，您在此同意将向该第三方主张与此有关的一切权利和损失。
        </p>
        <p>
          6.10
          北京小微之春科技有限公司平台上提供的产品或服务，如未标明使用期限、或者其标明的使用期限为“永久”、“无限期”或“无限制”的，则其使用期限为自您获得该产品或服务之日起至该产品或服务在
          北京小微之春科技有限公司下线之日为止。
        </p>
        <p>7.赔偿</p>
        <p>
          7.1如因我们违反有关法律、法规或本协议项下的任何条款而给您造成损失，我们同意承担由此造成的损害赔偿责任，赔偿金额限于您遭受的直接损失。在相关法律所允许的最大范围内，我们不就您因使用或不能使用服务所发生的间接损失（包括但不限于营业中断、利润损失、商业信息的丢失、费用增加等）承担赔偿责任；我们不对由于移动通信服务商、互联网接入服务商等第三方原因导致服务无法使用或出现故障所发生的损失承担责任。
        </p>
        <p>
          7.2如因您违反有关法律、法规或本协议项下的任何条款而给我们或任何其他第三人造成损失，您应承担由此造成的损害赔偿责任并保证我们免遭损失。
        </p>
        <p>8.协议修改</p>
        <p>
          8.1我们保留不时修改本协议任何条款的权利，一旦本协议的内容发生变动，我们将会在网站上公布修改之后的协议内容，若您不同意上述修改，则可以选择停止使用服务。我们也可选择通过其他适当方式向您通知修改内容。
        </p>
        <p>
          8.2如果不同意我们对本协议相关条款所做的修改，您有权停止使用服务。如果您继续使用服务，则视为您接受我们对本协议相关条款所做的修改。
        </p>
        <p>9.通知送达</p>
        <p>
          9.1本协议项下我们对于您所有的通知均可通过网页公告、电子邮件、系统通知、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。
        </p>
        <p>
          9.2您对于我们的通知应当通过我们对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。
        </p>
        <p>10.法律适用</p>
        <p>
          10.1本协议及其修订本的有效性、履行和解释均适用于中华人民共和国之法律，任何争议也仅适用中华人民共和国法律。如协议服务条款之任何一部分与中华人民共和国法律相抵触，则该部分条款应按法律规定重新解释，部分条款之无效或重新解释不影响其它条款之法律效力
          。
        </p>
        <p>
          因本协议所引起的您与我们的任何纠纷或争议，首先应本着友好协商原则进行解决，如果协商不成的，您在此完全同意将纠纷或争议提交至我们所在地即北京海淀区有管辖权的人民法院通过诉讼解决。
        </p>
        <p>11.其他规定</p>
        <p>
          11.1本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。
        </p>
        <p>
          11.2如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
        </p>
        <p>11.3本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog2">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "my-dialog",
  data() {
    return {
      showDialogA1: false,
      showDialogB1: false,
    };
  },
  props: {
    showDialogA: {
      type: Boolean,
      default: false,
    },
    showDialogB: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showDialogA: {
      handler: function (newVal) {
        this.showDialogA1 = newVal;
      },
      deep: true,
    },

    showDialogB: {
      handler: function (newVal) {
        this.showDialogB1 = newVal;
      },
      deep: true,
    },
  },
  methods: {
    closeDialog1() {
      this.showDialogA1 = false;
      this.$emit("closeDialogA", this.showDialogA1);
    },
    closeDialog2() {
      this.showDialogB1 = false;
      this.$emit("closeDialogB", this.showDialogB1);
    },
  },
};
</script>

<style scoped lang="less">
.dialog-page :deep(.el-dialog__body) {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}
.content {
  p {
    margin-bottom: 5px;
    line-height: 1.4;
  }
}
</style>
