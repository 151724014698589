<template>
  <div class="contact-page">
    <div class="contact-page-box">
      <div class="contact-page-line" @click="handleClick">
        <div class="icon-line"></div>
        <div class="concat-text">在线<br />咨询</div>
      </div>
      <div
        class="contact-page-wx"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
      >
        <div class="icon-wx"></div>
        <div class="concat-text">微信<br />咨询</div>
      </div>
      <div class="wx-code" v-show="show">
        <img :src="require(`@/assets/img/kefu.png`)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-index",
  data() {
    return {
      show: false,
      url: "https://p.qiao.baidu.com/cps/chat?siteId=18198636&userId=41232783&siteToken=d8b2e65b9b5d7183ddad8bd98d033a85",
    };
  },
  methods: {
    mouseOver() {
      this.show = true;
    },
    mouseLeave() {
      this.show = false;
    },
    handleClick() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.contact-page {
  .contact-page-box {
    height: 180px;
    background: #fafdff;
    box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 0.6);
    border-radius: 4px;
    .wx-code {
      width: 160px;
      height: 160px;
      position: absolute;
      top: 140px;
      left: -170px;
      z-index: 2200;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .concat-text {
      height: 30px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 1.2;
      margin-top: 6px;
    }
    .contact-page-wx {
      width: 60px;
      height: 90px;
      background: #ffffff;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: linear-gradient(0deg, #30be6c 0%, #4fcb6b 99%);
        box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 0.6);
        .icon-wx {
          background: url("@/assets/img/icon_hover_wx.png") center center;
        }
        .concat-text {
          color: #ffffff;
        }
      }
      .icon-wx {
        width: 31px;
        height: 26px;
        background: url("@/assets/img/icon_wx.png") center center;
        background-size: 31px 26px;
      }
    }
    .contact-page-line {
      width: 60px;
      height: 90px;
      background: #ffffff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: linear-gradient(0deg, #30be6c 0%, #4fcb6b 99%);
        box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 0.6);
        .icon-line {
          background: url("@/assets/img/icon_hover_zxkf.png") center center;
        }
        .concat-text {
          color: #ffffff;
        }
      }
      .icon-line {
        width: 27px;
        height: 27px;
        background: url("@/assets/img/icon_zxkf.png") center center;
        background-size: 27px 27px;
      }
    }
  }
}
</style>
