import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, /* 实际开发中，会根据开发环境设置不同的baseURL */
  timeout: 30000
})
// 请求拦截，在每个请求发出去之前，针对每个域名做不同的配置
service.interceptors.request.use(config => {
  return config
})

export default service
