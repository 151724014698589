<template>
  <div class="bg-banner" :class="[banner]" :style="{ height: height + 'px' }">
    <div class="banner-left" v-show="menuActive === '/'">
      <div style="margin-bottom: 40px">帮企业找到最好的贷款</div>
      <div><img src="../../assets/img/img_bsywa_btn.png" /></div>
    </div>

    <div class="banner-right" v-show="menuActive === '/'">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        class="demo-ruleForm"
        hide-required-asterisk:true
        inline-message:true
      >
        <el-form-item prop="company_name">
          <el-autocomplete
            class="inline-input"
            v-model="ruleForm.company_name"
            :fetch-suggestions="querySearch"
            placeholder="请输入企业名称"
            :trigger-on-focus="false"
            @select="handleSelect"
            @input="querySearch()"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入申请人名字"
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input
            v-model="ruleForm.mobile"
            placeholder="请输入申请人手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="bind_code">
            <el-input
              class="bind_code_input code"
              v-model="ruleForm.code"
              type="text"
              placeholder="请输入验证码"
            />
            <el-button
              @click.native.prevent="fetchVerify"
              class="codebtn"
              :disabled="disabled"
              v-if="show == 0"
              >获取验证码</el-button
            >
            <el-button
              @click.native.prevent="fetchVerify"
              class="codebtn"
              :disabled="disabled"
              v-if="show == 2"
              >重新获取验证码</el-button
            >
            <span v-if="show == 1" class="disbtn">{{ count }}后重新获取</span>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="applybtn" type="primary" @click="applyQuota"
            >免费申请额度</el-button
          >
        </el-form-item>
        <div class="agreement">
          <el-checkbox v-model="ruleForm.checked"></el-checkbox>
          阅读并同意 <span @click="handleDialog('regula')">《隐私条例》</span
          ><span @click="handleDialog('agree')">《用户使用协议》</span>
        </div>
      </el-form>
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <div class="my-dialog">
      <my-dialog
        :showDialogA="showDialogA"
        @closeDialogA="closeDialogA"
        :showDialogB="showDialogB"
        @closeDialogB="closeDialogB"
      ></my-dialog>
    </div>
  </div>
</template>

<script>
import myDialog from "./dialog.vue";
import { getCompany, getVerify, sendQuota } from "@/api/index.js";
export default {
  name: "myBanner",
  components: {
    myDialog,
  },
  data() {
    return {
      showDialogA: false,
      showDialogB: false,
      navList: [
        {
          name: "首页",
          path: "/",
          eng: "Free Business Loan",
          chi: "免费帮企业贷款",
        },
        {
          name: "产品中心首页",
          path: "/product",
          eng: "Product Center",
          chi: "产品中心",
        },
        {
          name: "关于我们",
          path: "/about",
          eng: "About Us",
          chi: "关于小微之春",
        },
        {
          name: "公告新闻",
          path: "/news",
          eng: "Announcement News",
          chi: "公告新闻",
        },
      ],
      restaurants: [],
      ruleForm: {
        name: "",
        company_name: "",
        mobile: "",
        code: "",
        source: 1,
        checked: false,
      },
      // 验证码按钮
      type: "",
      disabled: false,
      timer: null,
      count: "",
      show: 0,
    };
  },
  computed: {
    menuActive() {
      return this.$route.path;
    },
    banner() {
      return this.$route.path == "/"
        ? "index-banner"
        : this.$route.path.slice(1) + "-banner";
    },
    bannerData() {
      return this.navList.filter((item) => item.path === this.$route.path);
    },
    height() {
      return this.$route.path == "/"
        ? this.deviceAutoHeight("index")
        : this.$route.path.slice(1).indexOf("detail") > -1
        ? 0
        : this.$route.path.indexOf("credit") > -1
        ? 0
        : this.deviceAutoHeight();
    },
  },
  methods: {
    // 动态设置高度
    deviceAutoHeight(index) {
      let s_w = window.screen.width - 17,
        // s_h = window.screen.height,
        _w = 1920,
        _h = index == "index" ? 920 : 400,
        t = s_w / _w;
      let h = t * _h;
      return h;
    },
    // 免费申请
    applyQuota() {
      if (
        (this.ruleForm.company_name,
        this.ruleForm.mobile,
        this.ruleForm.name,
        this.ruleForm.code,
        this.ruleForm.checked == true)
      ) {
        sendQuota(this.ruleForm).then((res) => {
          if (res.data.errno == 200) {
            this.$alert(
              "您的申请已收到，随后会有专属客服联系您，请保持电话畅通！",
              {
                confirmButtonText: "确定",
                callback: () => {
                  this.ruleForm.company_name = "";
                  this.ruleForm.mobile = "";
                  this.ruleForm.name = "";
                  this.ruleForm.code = "";
                  this.ruleForm.checked = false;
                },
              }
            );
          } else {
            this.$message({
              message: res.data.errmsg,
              type: "error",
            });
          }
        });
      } else {
        this.$message("请输入完整的信息");
        if (this.checked == false) {
          this.$message("请勾选用户隐私协议");
        }
      }
    },
    // 获取验证码
    fetchVerify() {
      const checktel = /^1[0-9]{10}$/;
      if (
        this.ruleForm.mobile === "" ||
        this.ruleForm.mobile.length == !11 ||
        !checktel.test(this.ruleForm.mobile)
      ) {
        const times = 4; //倒计时时间
        if (!this.timer) {
          this.count = times;
          this.timer = setInterval(() => {
            if (this.count == !0) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
              this.$message({ message: "请填写正确的手机号", type: "warning" });
            }
          }, 1000);
        }
        this.ruleForm.mobile = "";
      } else {
        let params = { mobile: this.ruleForm.mobile };
        getVerify(params)
          .then((result) => {
            if (result.data.errno == 200) {
              console.log(result);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        const times = 60; //倒计时时间
        if (!this.timer) {
          this.count = times;
          this.show = 1;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= times) {
              this.count--;
            } else {
              this.show = 2;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    // 企业模糊查询
    querySearch(queryString, cb) {
      if (this.ruleForm.company_name.length >= 3) {
        let params = { keyword: this.ruleForm.company_name };
        getCompany(params)
          .then((res) => {
            this.restaurants = [];
            res.data.data.forEach((val) => {
              let obj = {
                value: val.name,
              };
              this.restaurants.push(obj);
            });
            cb(this.restaurants);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // @select 点击选中建议项时触发
    handleSelect(item) {
      this.ruleForm.company_name = item.value;
    },
    // 弹框条例和协议
    handleDialog(value) {
      if (value === "regula") {
        this.showDialogA = true;
      } else {
        this.showDialogB = true;
      }
    },
    closeDialogA(val) {
      this.showDialogA = val;
    },
    closeDialogB(val) {
      this.showDialogB = val;
    },
  },
};
</script>

<style scoped lang="less">
.bg-banner {
  &.index-banner {
    // height: 920px;
    background: url("../../assets/img/img_bsywa.png") center center;
    background-size: 100% 100%;
    position: relative;
    top: -80px;
    .banner-left {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);
      color: #ffffff;
      font-size: 52px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .banner-right {
      box-sizing: border-box;
      width: 400px;
      height: 471px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 182px;
      background-color: #ffffff;
      padding: 63px 40px 71px;
      .demo-ruleForm {
        width: 321px;
        margin: auto;
        :deep(.el-input__inner) {
          height: 47px;
        }
        :deep(.el-input__inner:focus) {
          border-color: #3dc36c;
        }
        :deep(.el-autocomplete) {
          width: 100%;
          margin-bottom: 15px;
          .el-input {
            font-family: PingFang;
            color: #999999;
            font-weight: 400;
            border-radius: 7px;
            font-size: 16px;
          }
        }
      }
      .el-form-item {
        margin-bottom: 0px;
      }
      .el-input {
        width: 100%;
        // height: 47px;
        margin-bottom: 15px;
        font-size: 16px;
        font-family: PingFang;
        color: #999999;
        font-weight: 400;
        border-radius: 7px;
      }
      .el-button {
        border: 0;
        background: none;
        padding: 0;
        border-radius: 0;
      }
      .codebtn {
        position: absolute;
        top: 16px;
        right: 18px;
        font-size: 16px;
        font-weight: 400;
        font-family: PingFang;
        color: #3dc36c;
        line-height: 15px;
        cursor: pointer;
        padding-left: 10px;
      }
      .disbtn {
        position: absolute;
        top: 11px;
        right: 18px;
        font-size: 16px;
        font-weight: 400;
        font-family: PingFang;
        color: #999999;
        line-height: 15px;
        padding-left: 10px;
      }
      .bind_code {
        width: 100%;
        height: 47px;
        position: relative;
      }
      .applybtn {
        width: 321px;
        height: 47px;
        color: #ffffff;
        font-weight: 400;
        font-size: 16px;
        line-height: 47px;
        border: 1px solid #ebeaea;
        background: linear-gradient(0deg, #30be6c 0%, #4fcb6b 99%);
        border-radius: 23px;
        margin: 29px 0 15px;
      }
      .agreement {
        font-size: 14px;
        font-family: PingFang;
        font-weight: 400;
        color: #666666;
        .el-checkbox {
          margin-right: 8px;
        }
        :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
          border-color: #3dc36c;
        }
        :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
          background-color: #3dc36c;
          border-color: #3dc36c;
        }
        :deep(.el-checkbox__inner:hover) {
          border-color: #3dc36c;
        }
        span {
          font-size: 14px;
          font-family: PingFang;
          font-weight: 400;
          color: #3dc36c;
          cursor: pointer;
        }
      }
    }
  }
  &.product-banner {
    // height: 400px;
    background: url("../../assets/img/img_cpbw.png") center center;
    background-size: 100% 100%;
  }
  &.about-banner {
    // height: 400px;
    background: url("../../assets/img/img_gybw.png") center center;
    background-size: 100% 100%;
  }
  &.news-banner {
    // height: 400px;
    background: url("../../assets/img/img_ggbw.png") center center;
    background-size: 100% 100%;
  }
}
</style>
