<template>
  <el-container id="app" class="container">
    <el-header height="80px"><my-header></my-header></el-header>
    <div class="my-banner"><my-banner></my-banner></div>
    <div class="my-contact"><my-contact></my-contact></div>
    <el-main>
      <div class="layout">
        <router-view></router-view>
      </div>
    </el-main>
    <my-footer></my-footer>
  </el-container>
</template>
<script>
import myHeader from "./components/common/header.vue";
import myFooter from "./components/common/footer.vue";
import myBanner from "./components/common/bgbanner.vue";
import myContact from "./components/common/contact.vue";
export default {
  name: "App",
  components: {
    myHeader,
    myFooter,
    myBanner,
    myContact,
  },
  created() {
    this.$nextTick(() => {});
  },
  mounted() {
    if (this._isMobile()) {
      window.location.href = `${process.env.VUE_APP_PC_URL}`;
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style scoped>
.my-contact {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 10;
}
.el-header {
  z-index: 10;
}
.el-card__body,
.el-main {
  padding: 0;
  overflow: visible;
  background: #f9f9f9;
}
</style>
