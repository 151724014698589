<template>
  <div class="header" :class="[menuActive !== '/' ? 'other-header' : '']">
    <div class="logo">
      <img
        :src="
          menuActive !== '/'
            ? require(`@/assets/img/xwzclogo_active.png`)
            : require(`@/assets/img/xwzclogo.png`)
        "
      />
    </div>
    <div class="navs">
      <div
        class="nav-tab"
        :class="[menuActive === item.path ? 'is-active' : '']"
        v-for="item in navList"
        :key="item.path"
        @click="selectMenu(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="tel">融资热线： 400-888-3969</div>
  </div>
</template>

<script>
export default {
  name: "myHeader",
  data() {
    return {
      navList: [
        { name: "首页", path: "/" },
        { name: "产品中心", path: "/product" },
        { name: "关于我们", path: "/about" },
        { name: "公告新闻", path: "/news" },
        { name: "授信联盟", path: "/credit" },
      ],
    };
  },
  computed: {
    menuActive() {
      return this.$route.path;
    },
  },
  methods: {
    selectMenu(item) {
      this.$router.push({
        path: item,
      });
    },
  },
};
</script>

<style scoped lang="less">
.header {
  width: 1200px;
  height: 80px;
  margin: auto;
  display: flex;
  z-index: 10;
  &.other-header {
    .tel {
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #30be6c;
      font-size: 16px;
      margin-top: 32px;
    }
    .navs {
      display: flex;
      height: 80px;
      margin-right: 60px;
      .nav-tab {
        height: 16px;
        // line-height: 23px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        padding-bottom: 12px;
        margin-top: 32px;
        color: #333333;
        font-weight: 400;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        margin-right: 40px;
        &.is-active {
          font-weight: 600;
          color: #3ac26c;
          border-bottom: 2px solid #3ac26c;
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    margin-right: 260px;
    img {
      display: block;
      height: 36px;
      width: 186px;
    }
  }
  .tel {
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    font-size: 16px;
    margin-top: 32px;
  }
  .navs {
    display: flex;
    height: 80px;
    margin-right: 60px;
    .nav-tab {
      height: 16px;
      // line-height: 23px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      padding-bottom: 12px;
      margin-top: 32px;
      color: #ffffff;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      margin-right: 40px;
      &.is-active {
        font-weight: 600;
        color: #3ac26c;
        border-bottom: 2px solid #3ac26c;
      }
    }
  }
}
</style>
