import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: resolve => { require(['@/pages/index/index.vue'], resolve) }
  },
  {
    path: '/product',
    name: 'product',
    component: resolve => { require(['@/pages/product/index.vue'], resolve) }
  },
  {
    path: '/about',
    name: 'about',
    component: resolve => { require(['@/pages/about/index.vue'], resolve) }
  },
  {
    path: '/news',
    name: 'news',
    component: resolve => { require(['@/pages/news/index.vue'], resolve) }
  },
  {
    path: '/detail',
    name: 'newsDetail',
    component: resolve => { require(['@/pages/news/detail.vue'], resolve) }
  },
  {
    path: '/credit',
    name: 'credit',
    component: resolve => { require(['@/pages/credit/index.vue'], resolve) }
  }

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

export default router
